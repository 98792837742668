<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/sales/materials')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12">
        <div class="mb-3 ">
          <label for="clientsId" class="form-label">
            الاسم
          </label>

          <input class="form-control" id="code" v-model="body.name" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3 ">
          <label for="contractsId" class="form-label">
            العقد
          </label>
          <select
            class="form-select form-control"
            id="contractsId"
            name="contractsId"
            v-model.number="body.contractsId"
          >
            <option
              v-for="item of contractsList"
              :key="item.id"
              :value="item"
              >{{ item.code }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6" v-if="body.contractsId">
        <div class="mb-3 ">
          <label for="clientsId" class="form-label">
            اسم العميل
          </label>
          <input
            class="form-control"
            id="clientsId"
            disabled
            v-model="body.contractsId.clientsId.name"
          />
        </div>
      </div>

      <div class="col-md-6" v-if="body.contractsId">
        <div class="mb-3 ">
          <label for="branchesId" class="form-label">
            الفرع
          </label>
          <input
            class="form-control"
            id="branchesId"
            disabled
            v-model="body.contractsId.branchesId.name"
          />
        </div>
      </div>

      <div class="col-md-6" v-if="body.contractsId">
        <div class="mb-3 ">
          <label for="usersId" class="form-label">
            مندوب المبيعات
          </label>
          <input
            class="form-control"
            id="usersId"
            disabled
            v-model="body.contractsId.usersId.name"
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3" v-if="body.contractsId">
          <label for="materialsNote" class="form-label">
            تفاصيل المواد الخام
          </label>
          <b-form-textarea
            id="textarea-small"
            name="materialsNote"
            disabled
            v-model="body.contractsId.materialsNote"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3">
          <label for="note" class="form-label">
            ملاحظات
          </label>
          <b-form-textarea
            id="textarea-small"
            name="note"
            v-model="body.note"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3 roles text-right">
          <div class="p-field-checkbox">
            <Checkbox
              id="orderDone"
              v-model="body.orderDone"
              :binary="true"
              :value="true"
            />
            <label for="orderDone">تم الطلب</label>
          </div>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        contractsId: null,
        note: null,
        orderDone: false,
      },
      contractsList: [],
    };
  },
  methods: {
    save() {
      if (this.body.name && this.body.contractsId && this.body.note) {
        this.$http
          .post(`materials`, {
            ...this.body,
            contractsId: this.body.contractsId.id,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم الاضافة بنجاح',
                life: 3000,
              });
              this.$router.push('/admin/sales/materials');
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },

  created() {
    if (!this.$checkRoles('materialsAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`contracts`).then((res) => {
      this.contractsList = res.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`materials/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.contractsId = this.body.contractsId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
